@layer tailwind-base {
  @tailwind base;
}
@layer tailwind-base, zelene,antd;

@tailwind components;
@tailwind utilities;

@layer components {
  .title {
    @apply font-[didot] text-[clamp(2.5rem,0.2941rem_+_3.0216vw,4rem)] font-bold leading-[40px] text-zelene-blue sm:leading-tight;
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }

  .custom-scroll::-webkit-scrollbar {
    height: 20px; /* Chiều cao của thanh cuộn ngang */
  }
}

::-webkit-scrollbar {
  width: 5px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--zelene-color-grey);
  /* border-radius: 5px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--zelene-color-red);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: pointer;
}

@layer zelene {
  :root {
    font-family: var(--font-hneu);
    --zelene-scale: 1;
    --zelene-color-blue: #1d3f75;
    --zelene-color-red: #b84e44;
    --zelene-color-yellow: #d29a2a;
    --zelene-color-white: #fff;
    --zelene-color-beige: #fffdf9;
    --zelene-color-cream: #fff4e4;
    --zelene-color-heavy-cream: #fcdcab;
    --zelene-color-heavy-beige: #fdefdb;
    --zelene-color-grey: #a7a7a7;
    --zelene-color-dark-blue: #102647;
    --zelene-color-black: #03070e;
    --radius: 0.5rem;
    /* 6 gradient */
    /* --zelene-color-gradient-beige-yellow: #be202a;
    --zelene-color-gradient-red: #be202a;
    --zelene-color-gradient-blue: #be202a;
    --zelene-color-gradient-cream: 'bg-[linear-gradient(90deg, _#FFFDF9_0%, _#FEE6BE_100%)]';
    --zelene-color-gradient-beige: 'bg-[linear-gradient(0deg,_#FFF9EF_0%,_#FEF1DD_100%)]';
    --zelene-color-gradient-white: #be202a; */

    --zelene-color-light-blue: #407cde;
    --zelene-color-slight-blue: #3c5984;
  }

  .dark {
  }
}

@font-face {
  font-family: 'wise';
  src: url('/fonts/Prettywise-Medium.ttf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: var(--font-hneu);
  src: url('/fonts/HelveticaNeueLight.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'didot';
  src: url('/fonts/Didot-Bold.ttf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body {
  .ant-layout-sider-trigger {
    color: #1979ff !important;
    box-shadow: -2px 1px 10px -1px rgba(0, 0, 0, 0.29);
  }

  .ant-drawer-close {
    position: absolute;
    /* top: 16px; */
    right: 0;
  }

  max-width: 100%;
  overflow-x: hidden;

  .ant-form-item-label {
    font-weight: 600;
  }

  .ant-modal-title {
    display: flex;
    justify-content: center;
    /* margin-right: 20px !important; */
  }
}

/* .ant-drawer-header-title > button:first-child {
  margin-top: 10px !important;
  top: 100;
} */

/* .my-carousel .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-carousel .slick-slide > div {
  margin-left: 55px; 
} */

.ant-tabs-tab-active {
  z-index: 999 !important;
}

.ant-tabs-nav-list > div[data-node-key='1'] {
  z-index: 888 !important;
}

.ant-tabs-nav-list > div[data-node-key='2'] {
  z-index: 777;
}

.ant-tabs-nav-list > div[data-node-key='3'] {
  z-index: 666;
}

* {
  scroll-behavior: auto;
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  /* &::-webkit-scrollbar {
    width: 5px;
    border-radius: 3px;
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: bisque;
  } */
}

.ant-tabs-ink-bar {
  background-color: transparent !important;
}

/* .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: transparent !important;
} */

.ant-collapse-content-box {
  background-color: var(--zelene-color-beige) !important;
}

.ant-collapse-expand-icon > span[aria-label='expanded'] svg {
  fill: white !important;
}

/* css time slot */

.rbc-event-label {
  display: none !important; /* Ẩn thời gian trước mỗi sự kiện */
}
.rbc-time-slot {
  min-height: 10px !important;
}

.rbc-calendar {
  /* height: 77vh !important; */
  overflow: auto;
  width: 100%;
}

.rbc-month-view {
  min-height: 100vh !important;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  position: relative;
}

.rbc-month-view .rbc-row.rbc-month-header {
  flex: none; /* Đảm bảo tiêu đề tháng không cuộn */
  /* margin-top: 50px; */
  position: sticky; /* Thay vì fixed, sử dụng sticky để duy trì tiêu đề trong khung nhìn */
  top: 50px; /* Đảm bảo tiêu đề luôn nằm ở đầu khi cuộn */
  z-index: 999;
  width: 100%; /* Đảm bảo tiêu đề chiếm toàn bộ chiều rộng của phần cha */
  /* background: #3c5984; */
  box-sizing: border-box; /* Đảm bảo padding và border được tính vào chiều rộng */
  /* padding: 0 10px; */
}

.rbc-month-view .rbc-month-row {
  display: flex;
  flex-direction: column;
  flex: 1; /* Cho phép cuộn cho phần ô ngày */
  overflow-y: auto; /* Chỉ cho phép cuộn theo chiều dọc */
  /* margin-top: 100px; */
}

.rbc-month-view .rbc-month-row:nth-of-type(n + 3) {
  margin-top: 0;
}

/* .rbc-month-view .rbc-month-row:first-of-type {
  margin-top: 100px;
} */

.rbc-month-view .rbc-day-bg {
  height: 120px; /* Tăng chiều cao của các ô ngày */
  position: relative;
  /*border: 1px solid #ddd; /* Đảm bảo có border cho các ô ngày */
  /*background-color: #fff; /* Đảm bảo màu nền cho các ô ngày */
}

.rbc-month-view .rbc-row-content {
  height: 100px; /* Tăng chiều cao của các dòng */
}

.rbc-month-row {
  min-height: 180px; /* Tăng chiều cao của mỗi hàng tháng */
}

.rbc-month-row .rbc-row-content {
  min-height: 150px !important; /* Tăng chiều cao của mỗi hàng tháng */
}

.rbc-month-row .rbc-row-bg {
  height: 150px !important; /* Tăng chiều cao của mỗi hàng tháng */
}

.rbc-month-view .rbc-header {
  height: 50px; /* Tăng chiều cao của các tiêu đề ngày trong tuần */
}

.rbc-month-view .rbc-event {
  height: 50px; /* Chiều cao của mỗi task */
  line-height: 1.2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 5px;
  box-sizing: border-box;
  background-color: #fafafa; /* Màu nền cho sự kiện để dễ phân biệt */
  border: 1px solid #ddd; /* Đảm bảo có border cho sự kiện */
}

.rbc-month-view .rbc-day-bg .rbc-event:nth-of-type(n + 4) {
  display: none; /* Ẩn các task từ task thứ 4 trở đi */
}

.rbc-month-view .rbc-more-link {
  display: block; /* Hiển thị chữ "more" */
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}

/* Drawer */
.ant-drawer-content-wrapper {
  z-index: 999999999999999999999 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper-wrapper {
  padding-bottom: 30px !important;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
input[type='week'],
select:focus,
textarea {
  font-size: 16px;
}

@media (max-width: 767px) {
  .ant-pagination-options {
    display: block !important; /* Đảm bảo luôn hiển thị */
    width: 100%; /* Đẩy xuống hàng */
    text-align: center; /* Căn giữa */
    margin-top: 10px; /* Khoảng cách giữa các phần tử */
  }
}

.ant-menu-submenu-title {
  padding-left: 0 !important;
}

.ant-menu-sub.ant-menu-inline {
  background-color: transparent !important;
}

.ant-menu-sub.ant-menu-inline > li {
  padding-left: 24px !important;
}

/* style parrent from child custom-select */

.custom-select {
  background-color: transparent !important;
}
.ant-select-dropdown:has(.custom-select) {
  background-color: transparent !important;
}
@layer base {
  :root {
    --radius: 0.5rem;
  }
}

/* Add margin to the bottom of each table row */
/* .custom-table .ant-table-tbody > tr {
  margin-bottom: 20px !important;
} */

/* If margin doesn't work due to table formatting, try padding */
/* .custom-table .ant-table-tbody > tr > td {
  padding-bottom: 20px !important;
} */

/* .ant-table-cell {
  margin-bottom: 20 !important;
} */

/* .custom-table {
  border-collapse: separate;
  border-spacing: 0 20px !important; 
} */

.custom-table .ant-table-tbody > tr > td {
  background-color: #f8f4ee; /* Màu nền của các ô */
  border-bottom: 8px solid #f8f4ee !important; /* Màu đỏ cho khoảng cách dưới */
}
